@tailwind base;
@tailwind components;
@tailwind utilities;

.circle {
    height: 70px;
    width: 70px;
    border-radius: 50%;
    background-color: #034766;
    color: white;
    position: fixed;
    bottom: 12px;
    left: 70px;
    transform: translate(-50%, -50%);
}

.circle:before,
.circle:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border: 1px solid #034766;
    border-radius: 50%;
}

.circle:before {
    animation: ripple 2s linear infinite;
}

.circle:after {
    animation: ripple 2s linear 1s infinite;
}

@keyframes ripple {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.3);
        opacity: 1;
    }

    100% {
        transform: scale(1.6);
        opacity: 0;
    }
}



.popupBtn button {
    padding: 13px 29px;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 5px;
    border: none;
    outline: none;
    background: crimson;
    color: white;
    cursor: pointer;
}

#portal .container {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.7);
}

.container .modal {
    z-index: 1;
    width: 350px;
    height: 220px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    position: relative;
    animation: animate 0.3s;
}

.modal button {
    position: absolute;
    top: 7px;
    right: 7px;
    padding: 8px 11px;
    background: crimson;
    color: white;
    font-weight: bold;
    border: none;
    outline: none;
    border-radius: 2px;
    cursor: pointer;
}

@keyframes animate {
    from {
        transform: scale(0.5);
    }

    to {
        transform: scale(1);
    }
}